











































































































































































import { Component, Vue } from "vue-property-decorator";
import BackIndicator from "@/components/BackIndicator.vue";

@Component({
  components: {
    BackIndicator,
  },
})
export default class PlayerDetails extends Vue {
  public activePlayer: null | any = null;

  get categoryDetailsData() {
    if (this.activePlayer === null) return null;
    return this.activePlayer.categoryScores.data;
  }

  backToResults() {
    this.$router.push("/");
  }

  created() {
    this.activePlayer = this.$store.state.activePlayer;
  }
}
